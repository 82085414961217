import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "table-thead" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "sorting-icon"
}
const _hoisted_4 = ["src"]

import { Table, FlexRender } from "@tanstack/vue-table";


import { defineComponent } from "vue";
const __default__ = defineComponent({
  methods: {
    setActionColumnSize(header: string) {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;

      if (header !== "Дії") {
        return {
          minWidth: "",
          maxWidth: "",
        };
      }

      if (!isMobile && header === "Дії") {
        return {
          minWidth: "4%",
          maxWidth: "4%",
        };
      }

      return {
        minWidth: "15%",
        maxWidth: "15%",
      };
    },
  },
});

interface TableHeaderProps {
  table: Table<any>;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'TableHeader',
  props: {
    table: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.getHeaderGroups(), (headerGroup) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "table-tr",
        key: headerGroup.id
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerGroup.headers, (header) => {
          return (_openBlock(), _createElementBlock("div", {
            key: header.id,
            class: "table-th table-cell",
            style: _normalizeStyle({
          ..._ctx.setActionColumnSize(header.column.columnDef.header as string),
        }),
            onClick: ($event: any) => (header.column.getToggleSortingHandler()?.($event))
          }, [
            (!header.isPlaceholder)
              ? (_openBlock(), _createBlock(_unref(FlexRender), {
                  key: 0,
                  render: 
            typeof header.column.columnDef.header === 'string'
              ? _ctx.$t(header.column.columnDef.header)
              : header.column.columnDef.header
          ,
                  props: header.getContext()
                }, null, 8, ["render", "props"]))
              : _createCommentVNode("", true),
            (header.column.getCanSort())
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: 
              {
                asc: require('@/assets/icons/sort-asc.svg'),
                desc: require('@/assets/icons/sort-desc.svg'),
              }[header.column.getIsSorted() as string]
            ,
                    alt: ""
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true)
          ], 12, _hoisted_2))
        }), 128))
      ]))
    }), 128))
  ]))
}
}

})