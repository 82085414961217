import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "disabled"]


import VSpinner from "@/components/spinner/VSpinner.vue";
import { ButtonHTMLAttributes, defineComponent } from "vue";

const __default__ = defineComponent({
  emits: ["click"],
  components: {
    VSpinner,
  },
});

type ButtonVariants = "primary" | "secondary" | "success" | "ghost" | "error";

interface ButtonProps {
  type?: ButtonHTMLAttributes["type"];
  variant?: ButtonVariants;
  rounded?: boolean;
  hasMaxWidth?: boolean;
  loading?: boolean;
  fixed?: boolean;
  className?: string;
  disabled?: boolean;
  size?: "sm" | "md";
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'BaseButton',
  props: {
    type: { default: "button" },
    variant: { default: "primary" },
    rounded: { type: Boolean, default: false },
    hasMaxWidth: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false },
    className: {},
    disabled: { type: Boolean },
    size: { default: "md" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass([`v-button--${_ctx.variant} ${_ctx.rounded ? 'v-button--rounded' : ''} ${
      _ctx.hasMaxWidth ? 'v-button--max-width' : ''
    } ${_ctx.fixed ? 'v-button--fixed' : ''} v-button--${_ctx.size} ${_ctx.className || ''} `, "v-button"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    disabled: _ctx.disabled
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(VSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _renderSlot(_ctx.$slots, "beforeIcon"),
          _renderSlot(_ctx.$slots, "text"),
          _renderSlot(_ctx.$slots, "afterIcon")
        ], 64))
  ], 10, _hoisted_1))
}
}

})