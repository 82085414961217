import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icons/select-check-icon.svg'


const _hoisted_1 = { class: "select-field__item-value" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "select-field__item-content" }
const _hoisted_4 = { class: "select-field__item-hint" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  alt: "Checked"
}


const __default__ = {
  emits: ["select"],
};

interface Option {
  label: string;
  value: string;
  icon?: string;
  hint?: string;
}

interface OptionItemProps {
  option: Option;
  checked: boolean;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'OptionItem',
  props: {
    option: {},
    checked: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-field__item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.option)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.option.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.option.icon,
            alt: "",
            class: "select-field__item-icon"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.option.label) + " ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.option.hint), 1)
      ])
    ]),
    (_ctx.checked)
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}
}

})