import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "form-field__label" }
const _hoisted_3 = {
  key: 0,
  class: "form-field__before-icon"
}
const _hoisted_4 = { class: "form-field__container" }
const _hoisted_5 = {
  key: 1,
  class: "form-field__error"
}
const _hoisted_6 = {
  key: 2,
  class: "form-field__hint"
}


import { defineComponent } from "vue";

const __default__ = defineComponent({
  computed: {
    hasBeforeIcon(): boolean {
      return Boolean(this.$slots.beforeIcon);
    },
    hasHint(): boolean {
      return Boolean(this.$slots.hint);
    },
    hasError(): boolean {
      return Boolean((this.$props as any).error);
    },
  },
});

interface FormFieldProps {
  label?: string;
  helpText?: string;
  centered?: boolean;
  shadow?: boolean;
  small?: boolean;
  error?: string;
  name?: string;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'FormField',
  props: {
    label: {},
    helpText: {},
    centered: { type: Boolean },
    shadow: { type: Boolean },
    small: { type: Boolean },
    error: {},
    name: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.name,
    class: _normalizeClass(["form-field", {
      'form-field--centered': _ctx.centered,
      'form-field--shadow': _ctx.shadow,
      'form-field--iconed': _ctx.hasBeforeIcon,
      'form-field--small': _ctx.small,
      'form-field--error': _ctx.hasError,
    }])
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label ? _ctx.$t(_ctx.label) : ""), 1),
    (_ctx.hasBeforeIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "beforeIcon")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.error)), 1))
      : _createCommentVNode("", true),
    (_ctx.hasHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "hint")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})