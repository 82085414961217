import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/logout_icon.svg'


const _hoisted_1 = { class: "logout-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_button = _resolveComponent("v-button")!

  return (_openBlock(), _createBlock(_component_v_button, {
    variant: "ghost",
    hasMaxWidth: false,
    onClick: _ctx.logout,
    "class-name": "logout-button"
  }, {
    text: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("Вийти")), 1)
    ]),
    afterIcon: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)
    ])),
    _: 1
  }, 8, ["onClick"]))
}