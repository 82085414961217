import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/chevron-down.svg'


const _hoisted_1 = { class: "app-menu__item app-menu__item--parent" }
const _hoisted_2 = { class: "app-menu__item-info" }
const _hoisted_3 = { class: "app-menu__subgroup-subchildren" }
const _hoisted_4 = { class: "app-menu__item-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-menu__subgroup", { active: _ctx.opened }])
  }, [
    _createElementVNode("div", {
      class: "app-menu__group-parent",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.menuIcons && _ctx.menuIcons[_ctx.submenu.NAME])
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "app-menu__item-icon",
                style: _normalizeStyle({maskImage: `url(${_ctx.menuIcons[_ctx.submenu.NAME]!})`, WebkitMaskImage: `url(${_ctx.menuIcons[_ctx.submenu.NAME]!})`})
              }, null, 4))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.submenu.NAME)), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "app-menu-subicon" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Open"
          })
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submenu.children, (child) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: child.NAME,
          to: {
          path: `/${child.LINK}`,
          query: {
            alloc_type: child.alloc_type || '',
          },
        },
          class: "app-menu__item"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.menuIcons && _ctx.menuIcons[child.NAME])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "app-menu__item-icon",
                    style: _normalizeStyle({maskImage: `url(${_ctx.menuIcons[child.NAME]!})`, WebkitMaskImage: `url(${_ctx.menuIcons[child.NAME]!})`})
                  }, null, 4))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(child.NAME)), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ], 2))
}