import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = { class: "select-field__dropdown" }
const _hoisted_3 = {
  key: 0,
  class: "select-field__item"
}


import { defineComponent } from "vue";
import SearchInput from "./SelectSearchInput.vue";
import SelectedInput from "./SelectSelectedInput.vue";
import OptionItem from "./OptionItem.vue";

interface Option {
  label: string;
  value: string;
  icon?: string;
  hint?: string;
}

const __default__ = defineComponent({
  emits: ["update:modelValue"],
  components: {
    SearchInput,
    SelectedInput,
    OptionItem,
  },
  data(): any {
    return {
      opened: false,
      value: this.modelValue || "",
      selectedLabel: "",
      selected: null,
    };
  },

  computed: {
    hasExtra() {
      return Boolean(this.$slots.extra);
    },
  },

  mounted() {
    document.addEventListener("keydown", this.onEscapePressed);
  },

  beforeUnmount() {
    document.removeEventListener("keydown", this.onEscapePressed);
  },

  watch: {
    selected() {
      this.value = this.selected?.label || "";
    },
    options() {
      this.selectedLabel =
        (this.$props.options as any).find(
          ({ value }: any) => value == this.$props.modelValue
        )?.label || "";
    },
    modelValue: {
      handler(selected) {
        this.selectedLabel =
          (this.$props.options as any).find(({ value }: any) => {
            return value == selected;
          })?.label || "";

        this.selected = (this.$props.options as any).find(({ value }: any) => {
          return value == selected;
        });
      },
      immediate: true,
    },
  },

  methods: {
    open() {
      this.opened = true;
    },
    toggle() {
      this.opened = !this.opened;
    },
    close() {
      this.opened = false;
    },
    select(option: Option) {
      this.selected = option;
      this.selectedLabel = option.label;
      this.$emit("update:modelValue", option.value);
      this.close();
    },
    clear() {
      this.value = "";
    },
    onEscapePressed(event: KeyboardEvent) {
      if (event.key === "Escape") this.close();
    },
  },
});

interface SelectFieldProps {
  name: string;
  options: Option[];
  inputPlaceholder?: string;
  searchPlaceholder?: string;
  hasSearch?: boolean;
  modelValue?: string;
  disabled?: any;
  size?: "md" | "sm";
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'SelectField',
  props: {
    name: {},
    options: {},
    inputPlaceholder: {},
    searchPlaceholder: {},
    hasSearch: { type: Boolean, default: true },
    modelValue: {},
    disabled: {},
    size: { default: "md" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-field", {
      'select-field--active': _ctx.opened,
      'select-field--disabled': _ctx.disabled,
    }]),
    ref: "selectField"
  }, [
    _createElementVNode("input", {
      type: "text",
      name: _ctx.name,
      class: "select-input-hidden"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["select-field__container form-field__input", `form-field__input--${_ctx.size}`]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.hasSearch ? _ctx.toggle() : _ctx.open()))
    }, [
      _withDirectives(_createVNode(SelectedInput, {
        label: _ctx.selectedLabel,
        placeholder: _ctx.inputPlaceholder ? _ctx.$t(_ctx.inputPlaceholder) : ''
      }, null, 8, ["label", "placeholder"]), [
        [_vShow, !_ctx.hasSearch || !_ctx.opened]
      ]),
      _withDirectives(_createVNode(SearchInput, {
        placeholder: _ctx.searchPlaceholder ? _ctx.$t(_ctx.searchPlaceholder) : '',
        onClear: _ctx.clear,
        name: _ctx.name
      }, null, 8, ["placeholder", "onClear", "name"]), [
        [_vShow, _ctx.opened && _ctx.hasSearch]
      ])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasExtra)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "extra")
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(OptionItem, {
          key: option.value,
          option: option,
          checked: _ctx.selected?.value == option.value,
          onSelect: _ctx.select
        }, null, 8, ["option", "checked", "onSelect"]))
      }), 128))
    ])
  ], 2)), [
    [_directive_click_outside, _ctx.close]
  ])
}
}

})