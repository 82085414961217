import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-sidebar__mobile-btn", { active: _ctx.active }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("div", { class: "line" }, null, -1),
    _createElementVNode("div", { class: "line" }, null, -1),
    _createElementVNode("div", { class: "line" }, null, -1)
  ]), 2))
}