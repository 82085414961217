import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/chevron-down-accent.svg'


const _hoisted_1 = { class: "selected-input" }
const _hoisted_2 = { class: "selected-input__field" }
const _hoisted_3 = {
  key: 0,
  class: "selected-input__placeholder"
}

import { computed } from "vue";
import { ellipsis } from "@/utils/ellipsis";
type SelectedInputProps = {
  placeholder: string;
  label: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSelectedInput',
  props: {
    placeholder: {},
    label: {}
  },
  setup(__props: any) {

const props = __props;
const truncatedLabel = computed(() => ellipsis(props.label));
const isMobile = computed(
  () => window.matchMedia("(max-width: 768px)").matches
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.label && _ctx.placeholder)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(isMobile.value ? truncatedLabel.value : _ctx.label), 1)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1))
  ]))
}
}

})