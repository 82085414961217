import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "app-layout__row" }
const _hoisted_3 = { class: "app-layout__content" }
const _hoisted_4 = { class: "app-layout__header" }
const _hoisted_5 = {
  key: 0,
  class: "app-layout__link"
}
const _hoisted_6 = {
  key: 1,
  class: "app-layout__text"
}
const _hoisted_7 = { class: "app-layout__title" }
const _hoisted_8 = { class: "app-layout__subtitle" }
const _hoisted_9 = { class: "app-layout__extra" }
const _hoisted_10 = { class: "app-layout__actions" }

import { computed, ref, useSlots } from "vue";
import { useRouterStore } from "@/app/router/router.store";
import AppLoading from "../AppLoading/AppLoading.vue";
import AppNavbar from "../AppNavbar/AppNavbar.vue";
import AppSidebar from "../AppSidebar/AppSidebar.vue";

type AppLayoutProps = {
  hasSidebar?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLayout',
  props: {
    hasSidebar: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const slots = useSlots();
const routerStore = useRouterStore();

const menuOpened = ref(false);
const hasAppLink = computed(() => Boolean(slots.appLink));
const hasAppTitle = computed(() => Boolean(slots.appTitle));
const routeLoading = computed(() => Boolean(routerStore.loading));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppNavbar, {
      onMobileButtonClick: _cache[0] || (_cache[0] = (value) => menuOpened.value = value)
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasSidebar)
        ? (_openBlock(), _createBlock(AppSidebar, {
            key: 0,
            menuOpened: menuOpened.value
          }, null, 8, ["menuOpened"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            _createVNode(AppLoading, { loading: routeLoading.value }, null, 8, ["loading"])
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "appLoading"),
        _createElementVNode("div", _hoisted_4, [
          (hasAppLink.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "appLink")
              ]))
            : _createCommentVNode("", true),
          (hasAppTitle.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "appTitle")
                ]),
                _createElementVNode("h4", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "appSubtitle")
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "appExtra")
          ])
        ]),
        _renderSlot(_ctx.$slots, "appContent"),
        _createElementVNode("div", _hoisted_10, [
          _renderSlot(_ctx.$slots, "appActions")
        ])
      ])
    ])
  ]))
}
}

})