import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/chevron-down.svg'


const _hoisted_1 = { class: "app-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "app-menu__item app-menu__item--parent" }
const _hoisted_4 = { class: "app-menu__item-info" }
const _hoisted_5 = { class: "app-menu__group-children" }
const _hoisted_6 = { class: "app-menu__item-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_submenu = _resolveComponent("app-submenu")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (menuGroup) => {
      return (_openBlock(), _createElementBlock("div", {
        key: menuGroup.title,
        class: _normalizeClass(["app-menu__group", { active: _ctx.activeIndex === menuGroup.index }])
      }, [
        _createElementVNode("div", {
          class: "app-menu__group-parent",
          onClick: ($event: any) => (_ctx.open(menuGroup.index))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.menuIcons && _ctx.menuIcons[menuGroup.title])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "app-menu__item-icon app-menu__item-icon--parent",
                    style: _normalizeStyle({maskImage: `url(${_ctx.menuIcons[menuGroup.title]!})`, WebkitMaskImage: `url(${_ctx.menuIcons[menuGroup.title]!})`})
                  }, null, 4))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(menuGroup.title)), 1)
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "app-menu-icon" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Open"
              })
            ], -1))
          ])
        ], 8, _hoisted_2),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuGroup.children, (child, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "app-menu__child",
              key: child.NAME
            }, [
              (Boolean(child.children))
                ? (_openBlock(), _createBlock(_component_app_submenu, {
                    key: 0,
                    submenu: child,
                    index: index
                  }, null, 8, ["submenu", "index"]))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: {
              path: `/${child.LINK}`,
            },
                    class: _normalizeClass([{
              'app-menu__item--active': _ctx.$route.path === child.LINK,
            }, "app-menu__item"])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        (_ctx.menuIcons && _ctx.menuIcons[child.NAME])
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "app-menu__item-icon",
                              style: _normalizeStyle({maskImage: `url(${_ctx.menuIcons[child.NAME]!})`, WebkitMaskImage: `url(${_ctx.menuIcons[child.NAME]!})`})
                            }, null, 4))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t(child.NAME)), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
            ]))
          }), 128))
        ])
      ], 2))
    }), 128))
  ]))
}