import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { expandedButton } from "@/components/table/ExpandedButton";
import { createColumnHelper } from "@tanstack/vue-table";
import { Transport } from "../models/transport";


import VTable from "@/components/table/VTable.vue";
import { defineComponent } from "vue";

const __default__ = defineComponent({
  components: {
    VTable,
  },
});

interface TransportsTableProps {
  data: Transport[];
  empty: boolean;
  total: number;
  hasRouteColumn?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'TransportsTable',
  props: {
    data: {},
    empty: { type: Boolean },
    total: {},
    hasRouteColumn: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const columnHelper = createColumnHelper<Transport>();

const columns = [
  columnHelper.accessor("transport_num", {
    cell: (info) => {
      if (props.hasRouteColumn) {
        return info.getValue();
      } else {
        const value = info.getValue();
        const isExpanded = info.row.getIsExpanded();
        const onClick = info.row.getToggleExpandedHandler();
        return expandedButton(value, isExpanded, onClick);
      }
    },
    header: "№ ТС",
    sortDescFirst: true,
  }),

  columnHelper.accessor("driver", {
    header: "Водій",
    cell: (info) => info.getValue(),
    sortDescFirst: true,
  }),
  columnHelper.accessor("geolocation", {
    header: "Розташування",
    cell: (info) => info.getValue(),
    sortDescFirst: true,
  }),
  columnHelper.accessor("drive_time", {
    header: "Час в дорозі",
    cell: (info) => info.getValue(),
    sortDescFirst: true,
  }),
];

if (props.hasRouteColumn) {
  columns.unshift(
    columnHelper.accessor("route", {
      cell: (info) => {
        const value = info.getValue();
        const isExpanded = info.row.getIsExpanded();
        const onClick = info.row.getToggleExpandedHandler();
        return expandedButton(value, isExpanded, onClick);
      },
      header: "Маршрут",
      sortDescFirst: true,
    })
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VTable, {
    data: _ctx.data,
    columns: columns,
    empty: _ctx.empty,
    total: _ctx.total
  }, null, 8, ["data", "empty", "total"]))
}
}

})