import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { createColumnHelper } from "@tanstack/vue-table";
import { Route } from "../models/routes";


import VTable from "@/components/table/VTable.vue";
import { defineComponent } from "vue";
import router from "@/app/router";
import { ROUTE_STORAGE_KEY } from "@/contants/storage";

const __default__ = defineComponent({
  components: {
    VTable,
  },
  methods: {
    onRowClick(row: any) {
      window.localStorage.setItem(
        ROUTE_STORAGE_KEY,
        JSON.stringify(row.original)
      );
      router.push({ name: "showRoute" });
    },
  },
});

interface TerminalTableProps {
  data: Route[];
  empty: boolean;
  total: number;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'RoutesTable',
  props: {
    data: {},
    empty: { type: Boolean },
    total: {}
  },
  setup(__props: any) {

const columnHelper = createColumnHelper<Route>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Назва",
    sortDescFirst: true,
  }),
  columnHelper.accessor("transport_num", {
    cell: (info) => info.getValue(),
    header: "К-сть ТС",
    sortDescFirst: true,
  }),

  columnHelper.accessor("avg_speed", {
    header: "Сер. швидкість потоку",
    cell: (info) => info.getValue(),
    sortDescFirst: true,
  }),
  columnHelper.accessor("avg_time", {
    header: "Сер. час проходження",
    cell: (info) => info.getValue(),
    sortDescFirst: true,
  }),
];



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VTable, {
    data: _ctx.data,
    columns: columns,
    empty: _ctx.empty,
    total: _ctx.total,
    onRowClick: _ctx.onRowClick
  }, null, 8, ["data", "empty", "total", "onRowClick"]))
}
}

})