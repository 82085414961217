import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/chevron-down.svg'


const _hoisted_1 = { class: "app-locales__item" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "app-locales__item-label" }
const _hoisted_4 = { class: "app-locales__dropdown" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]


import { defineComponent } from "vue";

type Locale = {
  icon: string;
  name: string;
  value: string;
};

const _locales: Locale[] = [
  {
    icon: require("@/assets/flags/uk.svg"),
    name: "Укр",
    value: "ua",
  },
  {
    icon: require("@/assets/flags/eng.svg"),
    name: "Eng",
    value: "en",
  },
];

const __default__ = defineComponent({
  data() {
    return {
      opened: false,
      current: _locales[0],
    };
  },

  mounted() {
    const locale = window.localStorage.getItem("locale");
    if (locale) {
      this.current = _locales.find((l) => l.value === locale) || _locales[0];
    }
  },

  computed: {
    locales(): Locale[] {
      return _locales.filter((locale) => locale.name !== this.current.name);
    },
  },

  methods: {
    toggleOpened() {
      this.opened = !this.opened;
    },
    close() {
      this.opened = false;
    },
    select(locale: Locale) {
      this.current = locale;
      this.close();

      this.$i18n.locale = locale.value;
      window.localStorage.setItem("locale", locale.value);

      window.location.reload();
    },
  },
});

interface AppLocalesProps {
  direction?: "top" | "bottom";
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'AppLocales',
  props: {
    direction: { default: "bottom" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-locales", `${_ctx.opened ? 'active' : ''} app-locales--${_ctx.direction}`])
  }, [
    _createElementVNode("div", {
      class: "app-locales__current",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpened && _ctx.toggleOpened(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.current.icon,
          alt: "UK"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.current.name), 1),
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "app-locales__icon",
          src: _imports_0,
          alt: "open"
        }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "app-locales__item",
          key: locale.name,
          onClick: ($event: any) => (_ctx.select(locale))
        }, [
          _createElementVNode("img", {
            src: locale.icon,
            alt: "UK"
          }, null, 8, _hoisted_6),
          _createTextVNode(" " + _toDisplayString(locale.name), 1)
        ], 8, _hoisted_5))
      }), 128))
    ])
  ], 2))
}
}

})