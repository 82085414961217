import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

import { ref } from "vue";
import TableBody from "./TableBody.vue";
import TableHeader from "./TableHeader.vue";
import TableEmpty from "./EmptyTable.vue";
import VPagination from "../panigation/VPagination.vue";

import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useVueTable,
} from "@tanstack/vue-table";

interface TableProps {
  columns: ColumnDef<any>[];
  data: any;
  empty: boolean;
  hasPagination: boolean;
  total?: number;
  striped?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VTable',
  props: {
    columns: {},
    data: {},
    empty: { type: Boolean },
    hasPagination: { type: Boolean },
    total: {},
    striped: { type: Boolean }
  },
  emits: ["row-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;
const sorting = ref<SortingState>([]);

const table = useVueTable({
  get data() {
    return props.data;
  },
  state: {
    get sorting() {
      return sorting.value;
    },
  },
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(sorting.value)
        : updaterOrValue;
  },
  columns: props.columns,
  getSubRows: (row) => row.subRows,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getExpandedRowModel: getExpandedRowModel(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.empty)
      ? (_openBlock(), _createBlock(TableEmpty, { key: 0 }))
      : (_openBlock(), _createElementBlock("table", {
          key: 1,
          border: "0",
          class: _normalizeClass(["table", { 'table--empty': _ctx.empty }]),
          "aria-describedby": "data visualized in a table format"
        }, [
          _createVNode(TableHeader, { table: _unref(table) }, null, 8, ["table"]),
          (!_ctx.empty)
            ? (_openBlock(), _createBlock(TableBody, {
                key: 0,
                striped: _ctx.striped,
                table: _unref(table),
                onRowClick: _cache[0] || (_cache[0] = (row) => _ctx.$emit('row-click', row))
              }, {
                "context-menu": _withCtx(({ values }) => [
                  _renderSlot(_ctx.$slots, "context-menu", { values: values })
                ]),
                expanded: _withCtx(({ values }) => [
                  _renderSlot(_ctx.$slots, "expanded", { values: values })
                ]),
                _: 3
              }, 8, ["striped", "table"]))
            : _createCommentVNode("", true)
        ], 2)),
    (_ctx.hasPagination)
      ? (_openBlock(), _createBlock(VPagination, {
          key: 2,
          total: _ctx.total
        }, null, 8, ["total"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})