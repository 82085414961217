import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/search-icon.svg'
import _imports_1 from '@/assets/icons/clear-icon.svg'


const _hoisted_1 = { class: "search-input" }
const _hoisted_2 = ["name", "placeholder"]


import { defineComponent } from "vue";
const __default__ = defineComponent({
  emits: ["clear"],
  mounted() {
    const input = this.$refs.input as HTMLInputElement;
    input.focus();
  },
});

interface SearchInputProps {
  name: string;
  placeholder?: string;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'SelectSearchInput',
  props: {
    name: {},
    placeholder: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      class: "search-input__icon search-input__before-icon"
    }, null, -1)),
    _createElementVNode("input", {
      name: _ctx.name,
      placeholder: _ctx.placeholder ? _ctx.$t(_ctx.placeholder) : '',
      type: "text",
      ref: "input"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      src: _imports_1,
      class: "search-input__icon search-input__after-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear')))
    })
  ]))
}
}

})