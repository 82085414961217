import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/user_avatar.svg'


const _hoisted_1 = { class: "app-user" }
const _hoisted_2 = { class: "app-user__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "app-user__avatar" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$store.state.users.user?.sh_name), 1)
  ]))
}