import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick", "onContextmenu"]
const _hoisted_3 = {
  key: 0,
  class: "table-expanded"
}
const _hoisted_4 = { class: "table-expanded__item-label" }
const _hoisted_5 = ["innerHTML"]


import { defineComponent } from "vue";
import { Table, FlexRender, Row } from "@tanstack/vue-table";

import VContextMenu from "../contenxt-menu/VContextMenu.vue";

const __default__ = defineComponent({
  emits: ["row-click"],
  components: {
    VContextMenu,
  },
  data() {
    return {
      selectedRowId: "",
    };
  },
  methods: {
    openContenxtMenu(event: MouseEvent, rowId: string) {
      this.selectedRowId = rowId;
      const { clientX: x, clientY: y } = event;

      const contextMenu = this.$refs.contextMenu as typeof VContextMenu;
      contextMenu.open(x, y);
    },

    toggleExpanded(row: Row<any>) {
      row.getToggleExpandedHandler()();
    },

    setActionColumnSize(header: string) {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;

      if (header !== "Дії") {
        return {
          minWidth: "",
          maxWidth: "",
        };
      }

      if (!isMobile && header === "Дії") {
        return {
          minWidth: "4%",
          maxWidth: "4%",
        };
      }

      return {
        minWidth: "15%",
        maxWidth: "15%",
      };
    },
  },
});

interface TableHeaderProps {
  table: Table<any>;
  striped?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'TableBody',
  props: {
    table: {},
    striped: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const displayCell = props.table
  .getAllColumns()
  .find((column) => column.id === "Дії");

const displayCellValues = (id: string) => {
  const row = props.table.getCoreRowModel().rows.find((row) => row.id === id);

  return row?.getVisibleCells()[0].getContext().row.original;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-tbody", { 'table-tbody--striped': _ctx.striped }])
  }, [
    _createVNode(VContextMenu, { ref: "contextMenu" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "context-menu", {
          values: displayCellValues(_ctx.selectedRowId)
        })
      ]),
      _: 3
    }, 512),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.getCoreRowModel().rows as Row<any>[], (row) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "table-row",
        key: row.id,
        onClick: ($event: any) => (_ctx.$emit('row-click', row))
      }, [
        _createElementVNode("div", {
          class: "table-tr",
          onClick: ($event: any) => (_ctx.toggleExpanded(row)),
          onContextmenu: _withModifiers((event) => _ctx.openContenxtMenu(event, row.id), ["stop","prevent"])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.getVisibleCells(), (cell) => {
            return (_openBlock(), _createElementBlock("div", {
              key: cell.id,
              class: "table-td table-cell",
              style: _normalizeStyle({
            color: row.original.status_color || '#000',
            fontStyle: row.original.fontStyle ? 'italic' : 'normal',
            ..._ctx.setActionColumnSize(cell.column.columnDef.header as string),
          })
            }, [
              _createVNode(_unref(FlexRender), {
                render: cell.column.columnDef.cell,
                props: cell.getContext()
              }, null, 8, ["render", "props"])
            ], 4))
          }), 128))
        ], 40, _hoisted_2),
        ((_ctx.table.getState().expanded as any)[row.id])
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "expanded", {
                values: row.subRows.map((subRow) => subRow.original)
              }, () => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(row.subRows[0].original), ([label, value]) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "table-expanded__item",
                    key: label
                  }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(label) + ":", 1),
                    _createElementVNode("div", {
                      class: "table-expanded__item-value",
                      innerHTML: value
                    }, null, 8, _hoisted_5)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    }), 128))
  ], 2))
}
}

})